import React from "react";
import "../styles/ContactUs.css";

import { Mail, Phone } from "@mui/icons-material";

function ContactUs() {
  return (
    <div className="contact-us">
      <h1>Contact Us</h1>

      {/* Contact form */}
      <form className="contact-form">
        <label htmlFor="name">Name:</label>
        <input type="text" id="name" name="name" required />

        <label htmlFor="email">Email:</label>
        <input type="email" id="email" name="email" required />

        <label htmlFor="message">Message:</label>
        <textarea id="message" name="message" rows="5" required></textarea>

        <button type="submit">Send Message</button>
      </form>

      {/* Contact icons */}
      <div className="contact-icons">
        <div className="contact-item">
          <Mail style={{ fontSize: "36px", color: "#12325b" }} />
          <a
            href="mailto:contact@risingstarspublicschool.com"
            className="contact-link"
          >
            Email Us
          </a>
        </div>

        <div className="contact-item">
          <Phone style={{ fontSize: "36px", color: "#12325b" }} />
          <a href="tel:+918534011812" className="contact-link">
            Call Us
          </a>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
