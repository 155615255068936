import { db } from "../config/firebaseConfig";
import { collection, getDocs } from "firebase/firestore";

class FireStoreService {
  static async fetchData(collectionName) {
    try {
      // Fetch data from Firestore
      const querySnapshot = await getDocs(collection(db, collectionName));

      // Check if there is data in the snapshot
      if (querySnapshot.empty) {
        console.log("No documents found in Firestore.");
        return [];
      }

      // Map documents to data format
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      console.log("Fetched data:", data);

      return data;
    } catch (error) {
      console.error("Error fetching data from Firestore:", error);
      throw new Error("Unable to fetch data from Firestore");
    }
  }
}

export default FireStoreService;
