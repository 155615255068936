import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Divider,
  Collapse,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { httpsCallable } from "firebase/functions";
import { functions } from "../config/firebaseConfig";
import FireStoreService from "../services/FireStoreService";

const JobListing = () => {
  const [jobs, setJobs] = useState([]);
  const collectionName = "JobsList";

  const [position, setPosition] = useState(0); // Change to null to start with no job selected
  const [formOpen, setFormOpen] = useState(false); // State to handle the modal visibility
  const [selectedIndex, setselectedIndex] = useState(0);
  const [formData, setFormData] = useState({
    email: "",
    fullName: "",
    dob: "",
    currentAddress: "",
    previousAddress: "",
    phoneNumber: "",
    bachelorDegree: "", // Update default value for bachelorDegree to be a string
    cv: null,
    microsoftOfficeExperience: "", // Update default value for microsoftOfficeExperience to be a string
  });

  const showJobDetails = (index) => {
    if (position === index) {
      setPosition(null); // If the same job is clicked again, toggle visibility
    } else {
      setPosition(index); // Show details for the clicked job
      setselectedIndex(index);
    }
  };

  const handleApplyNow = () => {
    setFormOpen(true); // Open the modal with the form
  };

  const handleCloseForm = () => {
    setFormOpen(false); // Close the modal
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      cv: file,
    }));
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmitForm = async (e) => {
    e.preventDefault();
    const file = formData.cv;

    if (
      !formData.email ||
      !formData.fullName ||
      !formData.dob ||
      !formData.cv
    ) {
      alert("Please fill out all required fields.");
      console.error("Validation failed: Missing required fields.");
      return;
    }

    const reader = new FileReader();

    reader.onloadend = async () => {
      const base64Data = reader.result.split("base64,")[1];
      const fileData = {
        base64: base64Data,
        type: file.type,
        name: file.name,
      };

      try {
        const response = await fetch(
          "https://script.google.com/macros/s/AKfycbxuZWv4l3SUy6GLphaqs7rqvg4pBB5zMdxI0KnXdxpnLP2YJAGReC1cdGvdoTNyZIw/exec", // Replace with your script URL
          {
            method: "POST",
            body: JSON.stringify(fileData),
          }
        );

        const result = await response.text();
        if (response.ok) {
          // Prepare the data to be sent to Google Apps Script
          const data = {
            Email: formData.email,
            FullName: formData.fullName,
            DOB: formData.dob,
            CurrentAddress: formData.currentAddress,
            PreviousAddress: formData.previousAddress,
            PhoneNumber: formData.phoneNumber,
            BachelorDegree: formData.bachelorDegree,
            MicrosoftOfficeExperience: formData.microsoftOfficeExperience,
            CV: result,
          };

          try {
            // Make a POST request to Google Apps Script web URL
            const response = await fetch(
              "https://script.google.com/macros/s/AKfycbzu7k9paGxEGZh9fDyZxnBo3TUGuk5R_mB_R6O8uPwwbeH19DMUYLH7uqRvUza3DE-R/exec",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
                body: new URLSearchParams(data).toString(),
              }
            );

            const result = await response.text();

            if (response.ok) {
              alert("Form submitted successfully!");
              console.log(result);
              handleCloseForm(); // Close the modal after successful submission
            } else {
              alert("Failed to submit the form. Please try again.");
              console.error("Form submission failed:", result);
            }
          } catch (error) {
            console.error("Error submitting the form:", error);
            alert(
              "An error occurred while submitting the form. Please check the console for more details."
            );
          }
        }
        console.log(result);
      } catch (error) {
        alert("Upload failed!");
        console.error("Error:", error);
      }
    };

    reader.readAsDataURL(file);
  };
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const jobsData = await FireStoreService.fetchData("JobsList");

        setJobs(jobsData);
        console.log("Jobs fetched:", jobsData);
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };

    fetchJobs();
  }, []);

  const convertToLocalTime = (seconds, nanoseconds) => {
    const milliseconds = seconds * 1000 + nanoseconds / 1000000;
    const date = new Date(milliseconds);
    return date.toLocaleString(); // Convert to local time string
  };

  const desiredOrder = [
    "Job Description",
    "Last Date",
    "Job Responsibilities",
    "Essential Requirements",
    "Desirable Requirements",
  ];
  const a = 0;

  const orderObject = (obj, order) => {
    console.log("ordering data ");
    console.log(obj);
    const orderedObj = {};
    order.forEach((key) => {
      if (obj[key]) {
        orderedObj[key] = obj[key];
      }
    });
    return orderedObj;
  };

  function TraceJson({ obj }) {
    console.log("rendering data");
    console.log(obj);
    let orderedObj = obj;

    if (obj && obj.Post === "Principal") {
      orderedObj = orderObject(obj, desiredOrder);
    }

    const renderKeyValue = (obj) => {
      if (obj === null || obj === undefined) {
        return <CircularProgress />;
      }

      return Object.entries(obj).map(([key, value]) => {
        if (typeof value === "object" && !Array.isArray(value)) {
          return (
            <div key={key}>
              <strong>{key}:</strong>
              <div style={{ marginLeft: "20px" }}>{renderKeyValue(value)}</div>
            </div>
          );
        } else if (Array.isArray(value)) {
          return (
            <div key={key}>
              <strong>{key}:</strong>
              <ul style={{ marginLeft: "20px" }}>
                {value.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          );
        } else {
          return (
            <div>
              {key !== "id" && key !== "Post" && (
                <div style={{ marginTop: "4px", marginBottom: "4px" }}>
                  <strong>{key}:</strong> {value}
                </div>
              )}
            </div>
          );
        }
      });
    };

    return <div>{renderKeyValue(orderedObj)}</div>;
  }

  return (
    <div>
      <Grid container spacing={2}>
        {/* Job Listings Column */}
        <Grid
          item
          xs={4}
          md={4}
          sx={{
            borderRight: "1px solid blue",
            maxHeight: "80vh", // Adjust the height as needed
            overflowY: "auto",
          }}
        >
          <Grid container spacing={2}>
            {jobs.map((job, index) => (
              <Grid item xs={12} key={index}>
                <Card
                  sx={{
                    maxHeight: "200px",
                    margin: "3px ",
                    padding: "10px",
                    border: "1px solid gray",
                    backgroundColor:
                      selectedIndex === index ? "lightcyan" : "white",
                  }}
                  onClick={() => showJobDetails(index)}
                >
                  <CardContent>
                    <Typography
                      variant="h7"
                      component="div"
                      sx={{ fontWeight: "bold", textAlign: "left" }}
                    >
                      {job.Post}
                    </Typography>

                    <Typography
                      variant="body"
                      sx={{
                        marginTop: 3,
                        textAlign: "center",
                        color: "gray",
                        fontSize: {
                          xs: "12px", // For extra small screens (mobile)
                          sm: "13pz", // For small screens (tablet)
                          md: "14px", // For medium screens (laptop)
                          lg: "1rem", // For large screens (desktop)
                        },
                        lineHeight: "10px",
                      }}
                    >
                      {job.Experience}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
        {/* // Job Details Column */}
        {console.log(jobs[position])}
        <Grid
          item
          xs={7}
          sm={7}
          md={7}
          sx={{
            margin: "6px",
            maxHeight: "80vh", // Adjust the height as needed
            overflowY: "auto",
            scrollbarWidth: "none", // Hide scrollbar in Firefox
          }}
        >
          <TraceJson obj={jobs[position]} />
          <p style={{ fontWeight: "bold", fontStyle: "italic", color: "red" }}>
            Application will only be accepted through online submission.
          </p>

          {jobs[position] && (
            <>
              {jobs[position].Post !== "Principal" && (
                <Button
                  variant="contained"
                  onClick={handleApplyNow}
                  sx={{ marginTop: 2 }}
                >
                  Apply Now
                </Button>
              )}

              {jobs[position].Post === "Principal" && (
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLScl0ZmvB8v1_MtTcnd1R2cYzWZeoGDMvc6Ejb8uXZQV7ftjNA/viewform"
                  style={{ color: "inherit", textDecoration: "none" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button variant="contained" sx={{ marginTop: 2 }}>
                    Apply Now
                  </Button>
                </a>
              )}
            </>
          )}

          {/* <Collapse in={position !== null}>
            <Typography
              variant="h7"
              sx={{
                fontWeight: "bold",
                fontSize: {
                  xs: "1rem", // For extra small screens (mobile)
                  sm: "1.2rem", // For small screens (tablet)
                  md: "1.5rem", // For medium screens (laptop)
                  lg: "1.8rem", // For large screens (desktop)
                },
                marginTop: 1,
              }}
            >
              Educational Qualifications:
            </Typography>
            <Typography variant="body1">
              {position !== null && jobs[position].eligibility.qualifications}
            </Typography>

            <Typography variant="h7" sx={{ fontWeight: "bold", marginTop: 1 }}>
              Experience:
            </Typography>
            {position !== null &&
              jobs[position].eligibility.experience.map((exp, index) => (
                <Typography key={index} variant="body1">
                  {exp}
                </Typography>
              ))}

            <Typography variant="h7" sx={{ fontWeight: "bold", marginTop: 1 }}>
              Skills:
            </Typography>
            {position !== null &&
              jobs[position].eligibility.skills.map((skill, index) => (
                <Typography key={index} variant="body1">
                  {skill}
                </Typography>
              ))}
            <Divider sx={{ my: 2 }} />
            {jobs[position].eligibility.experience.some(
              (exp) => exp !== "Coming soon. Please stay tuned for updates."
            ) && (
              <Button
                variant="contained"
                onClick={handleApplyNow}
                sx={{ marginTop: 2 }}
              >
                Apply Now
              </Button>
            )}
          </Collapse> */}
        </Grid>
      </Grid>
      <Dialog
        open={formOpen}
        onClose={handleCloseForm}
        fullWidth // This makes the dialog take up the full width
        maxWidth="md"
      >
        <DialogTitle>Job Application Form</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            <TextField
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              fullWidth
              required
            />
            <TextField
              label="Full Name"
              name="fullName"
              value={formData.fullName}
              onChange={handleInputChange}
              fullWidth
              required
            />
            <TextField
              label="Date of Birth"
              name="dob"
              value={formData.dob}
              onChange={handleInputChange}
              fullWidth
              required
            />
            <TextField
              label="Current Address"
              name="currentAddress"
              value={formData.currentAddress}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              label="Previous Address"
              name="previousAddress"
              value={formData.previousAddress}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              label="Phone Number"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              fullWidth
            />

            <FormControl component="fieldset">
              <Typography variant="body1">Bachelor Degree:</Typography>
              <RadioGroup
                name="bachelorDegree"
                value={formData.bachelorDegree}
                onChange={handleRadioChange}
                row
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>

            <FormControl component="fieldset">
              <Typography variant="body1">
                Microsoft Office Experience:
              </Typography>
              <RadioGroup
                name="microsoftOfficeExperience"
                value={formData.microsoftOfficeExperience}
                onChange={handleRadioChange}
                row
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>

            <Button
              variant="contained"
              component="label"
              sx={{ marginTop: "10px" }}
            >
              Upload CV
              <input
                type="file"
                name="cv"
                accept=".pdf,.doc,.docx"
                onChange={handleFileChange}
                hidden
                required
              />
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForm}>Cancel</Button>
          <Button type="submit" variant="contained" onClick={handleSubmitForm}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default JobListing;
